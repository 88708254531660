import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`8th August 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` can now render Markdown and `}<a parentName="p" {...{
            "href": "https://mermaid-js.github.io/mermaid/"
          }}>{`Mermaid`}</a>{`
formatted documentation for annotated services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4299"
          }}>{`#4299`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4304"
          }}>{`#4304`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Description(value = "### Markdown format", markup = Markup.MARKDOWN)
@Get("/markdown")
public HttpResponse markdown() {
  return HttpResponse.of(200);
}
`}</code></pre>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Description(value = "graph TD;\\n" +
                     " A-->B;\\n" +
                     " A-->C;\\n" +
                     " B-->D;\\n" +
                     " C-->D;",
             markup = Markup.MERMAID)
@Get("/mermaid")
public HttpResponse mermaid() {
  return HttpResponse.of(200);
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now bind decorators to each gRPC service using `}<a parentName="p" {...{
            "href": "type://GrpcServiceBuilder#addService(BindableService,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#addService(io.grpc.BindableService,java.lang.Iterable)"
          }}>{`type://GrpcServiceBuilder#addService(BindableService,Iterable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4311"
          }}>{`#4311`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4316"
          }}>{`#4316`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
  .addService(myGrpcService, List.of(decorator)) // 👈👈👈
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://UnframedGrpcErrorHandler#ofJson():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/UnframedGrpcErrorHandler.html#ofJson()"
          }}>{`type://UnframedGrpcErrorHandler#ofJson()`}</a>{` now conforms to
`}<a parentName="p" {...{
            "href": "https://cloud.google.com/apis/design/errors#error_model"
          }}>{`Google's error model`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4231"
          }}>{`#4231`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4384"
          }}>{`#4384`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService
  .builder()
  .addService(myGrpcServiceImpl)
  .unframedGrpcErrorHandler(
    UnframedGrpcErrorHandler.ofJson()) // 👈👈👈
  .build();
`}</code></pre>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "code": 2,
  "message": "A custom message!",
  ...
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now extract the innermost element of `}<a parentName="p" {...{
            "href": "type://Unwrappable:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Unwrappable.html"
          }}>{`type://Unwrappable`}</a>{` type objects easily using `}<a parentName="p" {...{
            "href": "type://Unwrappable#unwrapAll():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Unwrappable.html#unwrapAll()"
          }}>{`type://Unwrappable#unwrapAll()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4338"
          }}>{`#4338`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Foo foo = new Foo();
Bar<Foo> bar = new Bar<>(foo);
Qux<Bar<Foo>> qux = new Qux<>(bar);
assert qux.unwrapAll() == foo; // 👈👈👈
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{` now extends `}<a parentName="li" {...{
          "href": "type://Unwrappable:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Unwrappable.html"
        }}>{`type://Unwrappable`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4308"
        }}>{`#4308`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see an intermittent `}<a parentName="li" {...{
          "href": "type://IllegalStateException"
        }}>{`type://IllegalStateException`}</a>{` for gRPC services
when `}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useBlockingTaskExecutor(boolean)"
        }}>{`type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean)`}</a>{` is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4367"
        }}>{`#4367`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4374"
        }}>{`#4374`}</a></li>
      <li parentName="ul">{`Requests no longer hang when gRPC-JSON transcoding is enabled and gRPC decorators return an unframed response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4337"
        }}>{`#4337`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4348"
        }}>{`#4348`}</a></li>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
        }}>{`type://HttpFile`}</a>{` to serve files from the OSGI bundle. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4371"
        }}>{`#4371`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4373"
        }}>{`#4373`}</a></li>
      <li parentName="ul">{`A request's start time on the server side is now correctly
recorded when a server receives the first header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4365"
        }}>{`#4365`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4372"
        }}>{`#4372`}</a></li>
      <li parentName="ul">{`You no longer see a false positive warning of unknown `}<inlineCode parentName="li">{`MethodType`}</inlineCode>{` when using gRPC clients. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4363"
        }}>{`#4363`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NoSuchElementException`}</inlineCode>{` while a `}<a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{` is closed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4349"
        }}>{`#4349`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClosedSessionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ClosedSessionException.html"
        }}>{`type://ClosedSessionException`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ClosedStreamException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ClosedStreamException.html"
        }}>{`type://ClosedStreamException`}</a>{` are raised correctly according to their usage. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4380"
        }}>{`#4380`}</a></li>
      <li parentName="ul">{`The timeout for client's endpoint selection isn't clamped by `}<inlineCode parentName="li">{`responseTimeout`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4383"
        }}>{`#4383`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.13.9 → 5.13.10`}</li>
      <li parentName="ul">{`Bucket4j 7.5.0 → 7.6.0`}</li>
      <li parentName="ul">{`Curator 5.2.1 → 5.3.0`}</li>
      <li parentName="ul">{`Dropwizard metrics 2.1.0 → 2.1.1`}</li>
      <li parentName="ul">{`GraphQL-Java 18.2 → 19.0`}</li>
      <li parentName="ul">{`GraphQL-Kotlin 5.3.2 → 6.1.0`}</li>
      <li parentName="ul">{`gRPC-Java 1.47.0 → 1.48.0`}</li>
      <li parentName="ul">{`java-jwt 3.19.1 → 4.0.0`}</li>
      <li parentName="ul">{`Jetty 9.4.46.v20220331 → 9.4.48.v20220622`}</li>
      <li parentName="ul">{`kafka-clients 3.2.0 → 3.2.1`}</li>
      <li parentName="ul">{`Kotlin 1.7.0 → 1.7.10`}</li>
      <li parentName="ul">{`Kotlin Coroutine 1.6.3 → 1.6.4`}</li>
      <li parentName="ul">{`Micrometer 1.9.1 → 1.9.2`}</li>
      <li parentName="ul">{`Netty 4.1.78.Final → 4.1.79.Final`}</li>
      <li parentName="ul">{`Protobuf 3.19.2 → 3.21.1`}</li>
      <li parentName="ul">{`Reactor 3.4.19 → 3.4.21`}</li>
      <li parentName="ul">{`RESTEasy 5.0.2.Final → 5.0.4.Final`}</li>
      <li parentName="ul">{`Sangria 3.0.0 → 3.0.1`}</li>
      <li parentName="ul">{`Scala 2.12.15 → 2.12.16, 3.1.2 → 3.1.3`}</li>
      <li parentName="ul">{`scala-collection-compat 2.7.0 → 2.8.1`}</li>
      <li parentName="ul">{`Spring Boot 2.7.1 → 2.7.2`}</li>
      <li parentName="ul">{`Spring Web 5.3.21 → 5.3.22`}</li>
      <li parentName="ul">{`Tomcat8 8.5.78 → 8.5.81`}</li>
      <li parentName="ul">{`Tomcat9 9.0.62 → 9.0.65`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'cnabro', 'ikhoon', 'jrhee17', 'mauhiz', 'minwoox', 'mscheong01', 'natsumehu', 'ngyukman', 'proceane', 'ta7uw', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      